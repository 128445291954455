<template>
  <div>{{$route.params.id}}</div>
</template>
<script>
export default {
  props: {
    test: {
      type: String,
      required: false,
      default: 'testfailed'
    }
  }
}
</script>